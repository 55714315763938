﻿.multiselect {
    $height: 38px;

    .multiselect__tags {
        min-height: $height;
        height: $height !important;
        padding-top: 7px;
        border-radius: 0.25rem;
        border-color: #ced4da;

        .multiselect__tag {
            background-color: var(--primary);

            i.multiselect__tag-icon:hover {
                background-color: var(--primary);
            }
        }
    }

    .multiselect__option--highlight {
        background-color: var(--primary);

        &::after {
            background-color: var(--primary);
        }

        &.multiselect__option--selected {
            background-color: var(--danger);

            &::after {
                background-color: var(--danger);
            }
        }
    }
}

.multiselect-input-group {
    button.btn {
        float: left;
        width: 10%;
        height: 38px;
        border-radius: 5px 0px 0px 5px;
        -webkit-box-shadow: 0px 0px 0px 0px !important;
        box-shadow: 0px 0px 0px 0px !important;
        padding: 0;
    }

    .multiselect {
        width: 90%;

        .multiselect__tags {
            height: 40px;
            border-radius: 0px 5px 5px 0px;
        }
    }
}

@media (prefers-color-scheme: dark) {
    .multiselect, .multiselect__tags {
        color: var(--dark-border-primary);
    }
    .multiselect {
        .multiselect__tags {
            .multiselect__tag {
                background-color: var(--primary);
                color: var(--white);
            }
        }
    }
    .multiselect {
        .multiselect__tags {
            border-color: var(--dark-border-primary);
        }
    }
}
