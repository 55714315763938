
$dark-primary: #292E34;
$dark-secondary: #212529;
$dark-tertiary: #1B1F22;

$dark-border-primary: #7D7D7D;

$text-color-primary: #FAFAFA;

:root {
  --dark-primary: #{$dark-primary};
  --dark-secondary: #{$dark-secondary};
  --dark-tertiary: #{$dark-tertiary};

  --dark-border-primary: #{$dark-border-primary};

  --text-color-primary: #{$text-color-primary};
}
