// overrides the pagination
@media (prefers-color-scheme: dark) {
  .page-item,
  .page-item.disabled {
    .page-link {
      background-color: var(--dark-primary);
      border-color: var(--dark-border-primary);
    }
  }
}
