// Used to create hidden textareas that JavaScript writes content to
// Example, see ProjectCredentialPopup.vue->copyPassword()
textarea.copy-textarea
{
    visibility: hidden;
    width: 1px;
    height: 1px;
    border: none;
    background-color: transparent;
    color: transparent;
    padding: 0;
}
