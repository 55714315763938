// overrides the bootstrap modals
@media (prefers-color-scheme: dark) {
  .modal-header {
    .close {
      color: var(--white);
      &:hover {
        color: var(--white);
      }
    }
  }
  .modal-content {
    background-color: var(--dark-secondary);
  }
}
