





















































$popup-primary: #1e88e5;
$popup-secondary: #f2f4f8;
$popup-border-size: 0.2rem;
$popup-horizontal-padding: 1rem;
$popup-vertical-padding: 0.5rem;
.popup-container {
    width: 100%;
    z-index: 100;
    position: fixed;
    right: 20px;
    bottom: 20px;
}
.update-notification {
    border-radius: 0.5rem;
    background-color: $popup-secondary;
    border: $popup-border-size solid $popup-primary;
    display: flex;
    flex-wrap: nowrap;
}
.icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    i {
        padding: $popup-vertical-padding $popup-horizontal-padding;
    }
}
.text-container {
    margin-right: 0.5rem;
    .popup-title {
        margin-top: 0.5rem;
        font-size: 1.25rem;
        line-height: 1.25rem;
    }
}
.right-buttons {
    display: flex;
    flex-direction: column;
    .popup-button {
        padding: $popup-vertical-padding $popup-horizontal-padding;
        font-weight: 400;
        min-width: 100%;
        flex-grow: 1;
        text-align: center;
        border-radius: 0;
        border: none;
        border-left: $popup-border-size solid $popup-primary;
        &:not(:first-child) {
            border-top: $popup-border-size solid $popup-primary;
        }
        &:first-child {
            border-top-right-radius: $popup-border-size;
        }
        &:last-child {
            border-bottom-right-radius: $popup-border-size;
        }
        &:hover {
            background-color: $popup-primary;
            color: $popup-secondary;
        }
    }
}
