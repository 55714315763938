// overrides css for styles/theme/blue.css
@media (prefers-color-scheme: dark) {
  .sidebar-nav {
    ul li {
      a,
      &.nav-small-cap {
        color: var(--white);
      }
    }

    > ul > li > a i {
      color: var(--white);
    }
  }
}
