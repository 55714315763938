@import "_theme";
@import "_blue";

.chat-list {
    li {
        .chat-content {
            width: 100%;
            overflow-x: auto;

            img {
                max-width: 100%;
                height: auto;
            }
        }
    }
}

.chat-list li .chat-content .box {
    @media (prefers-color-scheme: dark) {
        background-color: #000;
        color: #dee2e6;
    }
}

html body .bg-light-danger, html body .bg-light-danger[readonly]{
    /* --danger color at 60% opacity*/
    background-color: color-mix(in srgb, var(--danger) 80%, transparent);
    color: var(--black)  !important;
}

html body .bg-light-success, html body .bg-light-success[readonly] {
    /* --success color at 60% opacity*/
    background-color: color-mix(in srgb, var(--success) 60%, transparent);
    color: var(--black) !important;
}

html body .bg-light-info {
    background-color: var(--primary);
}

.box.bg-light-inverse {
  i {
    color: inherit;
  }
}

.user-profile {
    text-align: center;

    .profile-img {
        width: auto;
        margin-left: 0;
    }
}

/*This "resets" the button group stickiness at the "sm" breakpoint (so that they can break apart)*/
@media (max-width: 575px) {
    .btn-group {
        display: block;

        > .btn:not(:last-child) {
            border-top-right-radius: 0.25rem !important;
            border-bottom-right-radius: 0.25rem !important;
        }

        > .btn:not(:first-child) {
            border-top-left-radius: 0.25rem !important;
            border-bottom-left-radius: 0.25rem !important;
        }
    }
}

@media (min-width: 768px) {
    .mini-sidebar.fix-sidebar .left-sidebar {
        position: fixed;
    }
}

// overrides for dark mode
.page-wrapper,
.page-titles,
.footer,
.left-sidebar,
.sidebar-nav {
    background: transparent;
}

@media (prefers-color-scheme: dark) {
    .chat-list li .chat-content .box {
        background-color: #000;
        color: #dee2e6;
    }

    .b-toast .toast {
        background-color: #212529;
    }

    .footer {
        background-color: var(--dark-primary) !important;
    }

    body {
        background-color: var(--dark-tertiary) !important;
        color: var(--text-color-primary) !important;
    }
}
