.modal-body {
    & .word-break {
        overflow-wrap: break-word;
    }
}

.modal-header {
    .modal-title {
        width: 100%;
        .time-spent {
            float: right;
        }
    }
}

.modal img {
    max-width: 100%;
}
