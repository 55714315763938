





























.page-titles .breadcrumb .breadcrumb-item + .breadcrumb-item::before,
.page-titles .breadcrumb .breadcrumb-item + .breadcrumb-item:before {
    content: "\f054";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-size: unset;
}
