// overrides the left sidebar styles
@media (prefers-color-scheme: dark) {
  .sidebar-nav {
    background-color: var(--dark-secondary);
    > ul > li > {
      a.active {
        background-color: inherit !important;
        color: var(--primary) !important;
      }
    }
  }

  .sidebar-footer {
    background-color: var(--dark-primary) !important;
  }

  .nav-devider {
    background-color: var(--white) !important;
    opacity: .15;
  }

  .user-profile .profile-text {
    color: var(--text-color-primary);
    background-color: var(--dark-primary);
  }
}
