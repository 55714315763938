table.table {
    tbody.condensed {
        tr {
            td:not(.not-condensed) {
                max-width: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }

    .small-font > &,
    & {
        &,
        .form-control,
        .btn {
            font-size: 0.8rem;
        }
    }

    tbody {
        &:not(.normal-break) td {
            word-break: break-word;
        }

        &.word-break td {
            word-break: break-all;
        }

        td img {
            max-width: 100%;
            height: auto;
        }

        //TODO: component/page specific styles should be in separate files
        &.time-entry-table-body td {
            &.description-column {
                max-width: 500px;
            }

            &.client-project-column {
                max-width: 200px;
            }

            &.task-name-column {
                max-width: 250px;
            }
        }
    }

    .admin-action-column {
        width: 125px;
    }

    .credential-notes-column {
        min-width: 100px;
    }

    .credential-modal-notes-column {
        max-width: 200px;
    }

    .credentials-name-column {
        min-width: 150px;
    }

    .credentials-username-column {
        min-width: 250px;
    }

    .todo-description-column {
        min-width: 300px;
    }

    .button-column {
        min-width: 75px;
    }
}

@media (max-width: 1780px) {
    .table-responsive-xxl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-xxl > .table-bordered {
        border: 0;
    }
}
