// overrides bootstrap typography styles
@media (prefers-color-scheme: dark) {
  a.link {
    color: var(--white);
  }
  a {
    color: var(--white);
  }
  .text-info,
  html body .text-themecolor,
  .sidebar-nav > ul > li > a.active,
  .text-themecolor {
    color: var(--primary) !important;
  }
}
