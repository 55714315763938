// overrides the top navbar styles
@media (prefers-color-scheme: dark) {
  .navbar-themed {
    background-color: var(--dark-primary) !important;
  }
  .page-titles .breadcrumb .breadcrumb-item.active {
    color: #cfd8ed;
  }
  .bc-colored .breadcrumb-item + .breadcrumb-item:before {
    color: #d9d9d9;
  }
  .navbar-dark .navbar-nav .nav-link, .navbar-themed .navbar-nav .nav-link {
    color: var(--white);
  }
}
