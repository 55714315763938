.sticky-footer {
    position: sticky;
    bottom: 0;
    z-index: 100;
    width: 100%;
    border: 2px solid #1e88e5;
    box-shadow: 5px 0px 10px #00000080;
}

@media (prefers-color-scheme: light) {
    .sticky-footer {
        background-color: #f2f4f8;
    }
}

@media (prefers-color-scheme: dark) {
    .sticky-footer {
        background-color: var(--dark-secondary);
    }
}
