// overrides forms and inputs
@media (prefers-color-scheme: dark) {
  .b-form-datepicker {
    .dropdown-menu {
      background-color: var(--dark-primary);
    }

    button {
      color: var(--primary);
    }
  }
  .form-check-input {
    accent-color: #2CB9E2;
  }
  .custom-select {
    background: var(--white) url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23fafafa' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right .75rem center / 8px 10px no-repeat;
    color: var(--text-color-primary);
    background-color: var(--dark-primary);
    border-color: var(--dark-border-primary);
  }
  .multiselect__select:before {
    border-color: var(--text-color-primary) transparent transparent;
  }
  .multiselect,
  .multiselect__tags,
  .multiselect__option {
    color: var(--text-color-primary);
    background-color: var(--dark-primary);
    border-color: var(--dark-border-primary);
  }
  .multiselect__option--selected {
    background-color: var(--dark-tertiary);
  }
  .form-control {
    color: var(--text-color-primary) !important;
    background-color: var(--dark-primary) !important;
    border-color: var(--dark-border-primary) !important;

    &:focus {
      color: var(--text-color-primary);
      background-color: var(--dark-primary);
    }

    &:disabled,
    &[readonly] {
      background-color: var(--dark-tertiary);
    }
  }
  .autocomplete {
    .autocomplete-results {
      li {
        color: var(--text-color-primary) !important;
      }
      background: var(--dark-primary) !important;
      border-color: var(--dark-border-primary) !important;
    }
  }
}
