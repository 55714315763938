﻿// vuejs-datepicker
.vdp-datepicker {
    input[readonly] {
        // override theme styling for readonly inputs
        &.form-control {
            background-color: #fff;
            color: #495057;
        }
    }
    .vdp-datepicker__calendar {
        color: black;
    }
    .input-group-prepend {
        .input-group-text {
            background-color: var(--primary);
            color: var(--white);
            border-color: var(--primary);
        }
    }
}

// bootstrap vue datepicker
.b-form-date-calendar {
    .form-control {
        display: block;
    }
}

@media (prefers-color-scheme: dark) {
  .vdp-datepicker {
    input[readonly] {
      &.form-control {
        background-color: var(--dark-primary);
        color: var(--text-color-primary);
      }
    }

    .vdp-datepicker__calendar {
      color: var(--text-color-primary) !important;
    }
  }

  .vdp-datepicker__calendar {
    background: var(--dark-primary) !important;
    border: 1px solid var(--dark-border-primary) !important;

    header {
      .up {
        &:not(.disabled):hover{
          background: var(--dark-primary) !important;
        }
      }
    }
  }
}
