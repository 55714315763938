// overrides bootstrap table styles
@media (prefers-color-scheme: dark) {
  .table {
    color: var(--white);
    thead {
      th {
        border: 0;
      }
    }
  }

  .table-bordered {
    &,
    & th,
    & td{
      border-color: var(--dark-tertiary);
    }
  }

  .tasks-index th.time-border-right {
    border: 0 !important;
  }

  .table-striped tbody tr {
    td,
    th,
    .time-border-right {
      border-color: var(--dark-tertiary);
    }
    &:nth-child(odd) {
      background-color: var(--dark-tertiary);
    }
  }
}

