.btn-info.disabled, .btn-info:disabled,
.btn-secondary.disabled, .btn-secondary:disabled,
.btn-primary.disabled, .btn-primary:disabled {
  background-color: var(--dark);
  border-color: var(--dark);
}

.btn:hover {
  color: var(--primary);
}

.btn-warning {
  &,
  .fas{
    color: var(--white) !important;
  }
}
