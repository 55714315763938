﻿body.mini-sidebar {
    .navbar-brand .logo-text {
        display: none !important;
    }
}

body:not(.mini-sidebar) {
    .navbar-brand .logo-small {
        display: none !important;
    }

    .sidebar-nav > ul > li > a i {
        margin-right: 5px;
    }
}

/* Hide left sidebar scrollbar */
.left-sidebar {
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
}
.left-sidebar::-webkit-scrollbar {
    /* WebKit */
    width: 0px;
}

.mini-sidebar {
    .left-sidebar {
        overflow-y: inherit; /* The sidebar wasn't designed to scroll when collapsed - see original design */

        @media (max-height: 800px) {
            .sidebar-nav > ul > li {
                margin-bottom: 5px;
            }
        }
    }
}

.profile-icon.img-fluid {
    max-width: 20px;
    line-height: 1;
    border-radius: 100%;
}

.user-profile {
    .profile-img {
        border-radius: 0;
        padding: 0;

        &:before {
            animation: none;
        }
    }
}

@media (min-width: 768px) {
    .mini-sidebar .user-profile .profile-img {
        padding: 0;
        margin: 0;
    }
}

@media (prefers-color-scheme: light) {
    .topbar .navbar-themed {
        background: #1e88e5 !important;
    }
}
