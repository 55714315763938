.autocomplete {
    position: relative;

    .autocomplete-results {
        padding: 0;
        margin: 0;
        border: 1px solid #CCC;
        height: auto;
        max-height: 500px;
        overflow: auto;
        width: 100%;
        position: absolute;
        z-index: 99999;
        background: #FFF;

        li {
            list-style: none;
            text-align: left;
            padding: .375rem .75rem;
            color: #000;

            &:not(.no-results) {
                cursor: pointer;

                &:hover {
                    background: var(--primary);
                    color: #FFF;
                }
            }

            &.no-results {
                color: #99abb4;
                font-style: italic;
            }
        }
    }
}
